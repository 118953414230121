import React from "react"
import PropTypes from "prop-types"

import Box from "./box"

const PaperShred = ({ bg, color, children }) => {
  /* random number between 1 and 4 */
  const randomNumber = Math.floor(Math.random() * (4 - 1 + 1) + 1)

  /* angles for rotated box */
  const angles = {
    1: "-2deg",
    2: "-1.5deg",
    3: "1.5deg",
    4: "2deg",
  }

  const polygons = {
    1: "polygon(4.89% 10.81%, 17.35% 21.15%, 27.15% 9.9%, 40.16% 13.26%, 53.38% 13.89%, 67.98% 14.58%, 80.47% 15.17%, 89.16% 15.58%, 100.93% 17.57%, 99.05% 91.64%, 88.93% 85.62%, 79.56% 99.49%, 61.39% 87.9%, 46.72% 87.23%, 39.99% 78.66%, 26.27% 92.58%, 12.25% 89.19%, -1.38% 82.43%, 2.27% 43.25%)",
    2: "polygon(4.89% 10.81%, 12.48% 13.04%, 27.15% 9.9%, 36.15% 11.7%, 53.38% 13.89%, 61.75% 15.72%, 71.84% 22.17%, 80.47% 15.17%, 89.16% 34.5%, 97.14% 17.57%, 101.21% 53.33%, 97.97% 85.58%, 88.93% 85.62%, 79.56% 99.49%, 61.39% 87.9%, 53.26% 71.31%, 46.72% 87.23%, 37.83% 70.55%, 31.12% 92.82%, 20.86% 84.47%, 12.25% 89.19%, 5.11% 82.43%, 2.27% 43.25%)",
    3: "polygon(8.8% 27.03%, 17.35% 21.15%, 29.38% 20.71%, 37.93% 2.45%, 60.64% 22%, 67.98% 14.58%, 80.47% 15.17%, 89.16% 15.58%, 98.7% 14.87%, 99.05% 64.61%, 88.93% 85.62%, 76.21% 67.06%, 72% 87.9%, 56.77% 87.23%, 39.99% 78.66%, 21.24% 97.99%, 12.25% 89.19%, 4.21% 79.73%, 2.27% 43.25%)",
    4: "polygon(4.89% 10.81%, 17.35% 21.15%, 27.15% 9.9%, 40.16% 13.26%, 53.38% 13.89%, 67.98% 14.58%, 80.47% 15.17%, 89.16% 15.58%, 100.93% 17.57%, 99.05% 91.64%, 88.93% 85.62%, 79.56% 99.49%, 61.39% 87.9%, 46.72% 87.23%, 39.99% 78.66%, 26.27% 92.58%, 12.25% 89.19%, -1.38% 82.43%, 2.27% 43.25%)",
  }

  const randomAngle = angles[randomNumber]
  const randomPolygon = polygons[randomNumber]

  return (
    <Box
      sx={{
        display: "inline-block",
        bg: bg,
        color: color,
        px: 4,
        py: 3,
        clipPath: randomPolygon,
        fontSize: 3,
        transform: `rotate(${randomAngle})`,
      }}
    >
      {children}
    </Box>
  )
}

PaperShred.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
}

PaperShred.defaultProps = {
  bg: "white",
  color: "black",
}

export default PaperShred
