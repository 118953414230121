import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Inline from "../../../components/inline"
import Layout from "../../../layouts/default"
import MarkerHighlight from "../../../components/marker-highlight"
import PageTitle from "../../../components/page-title"
import PaperShred from "../../../components/paper-shred"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import Trivia from "../../../components/trivia"

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopMaiPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/mai/materialien.jpg" }
      ) {
        ...largeImage
      }
      beispiel: file(
        relativePath: { eq: "workshops/kalender/mai/beispiel.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-mai">
      <Seo
        title="Mai"
        description="Hölderlin war ein Meister darin, sich neue Wörter auszudenken. Diese Art von Wörtern nennt man Neologismen und sie werden meist aus schon bestehenden Wörtern neu zusammengesetzt - ein bisschen wie bei einem Puzzle. Jetzt bist du an der Reihe! Für den Mai baust du aus Hölderlins Neologismen neue Wörter zusammen und bastelst eine Collage."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "Mai",
              link: "/workshops/kalender/mai",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>Mai</PageTitle>
            <Paragraph dropcap={true}>
              Für den Mai haben wir uns etwas Lustiges ausgedacht! Hölderlin war
              ein Meister darin, sich neue Wörter auszudenken. Diese Art von
              Wörtern nennt man Neologismen und sie werden meist aus schon
              bestehenden Wörtern neu zusammengesetzt – ein bisschen wie bei
              einem Puzzle. Jetzt bist du an der Reihe! Für den Mai baust du aus
              Hölderlins Neologismen neue Wörter zusammen und bastelst eine
              Collage.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Stack>
                <Heading as="h2" level={2}>
                  Du brauchst...
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Den Zettel mit den Begriffen aus unserem Bastelset oder
                      eigene kleine Zettel, auf die du die Begriffe aufschreiben
                      kannst, die du zu einem neuen Wort zusammenpuzzeln
                      möchtest.
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Eine Schere
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Eine Zeitungsseite
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Einen Klebestift
                    </Text>
                  </Checkbox>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Gedruckte Anleitung für den Workshop und Wörter zum Ausschneiden"
                />
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Wir haben für euch bereits ein paar von Hölderlins
                    Neologismen in ihre Einzelteile zerlegt. Schneide die
                    Begriffe auf dem Zettel aus deinem Bastelset aus oder
                    schreibe sie dir auf einen kleinen Zettel auf.
                  </Paragraph>
                </StepsBox>

                <Inline alignX="center" space={6}>
                  <PaperShred bg="letter" color="text">
                    freundlich
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    glänzend
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    furchtbar
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    entscheidend
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    falken
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    murmelnd
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    duldend
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    umstrahlt
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    ruhig
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    heilig
                  </PaperShred>
                  <PaperShred bg="letter" color="text">
                    ruhm
                  </PaperShred>
                </Inline>
                <StepsBox step={2}>
                  <Paragraph>
                    Setze die Schnipsel anschließend wie Puzzleteile zusammen
                    und erfinde neue Wörter. Sie können so lang und so verrückt
                    sein, wie du möchtest. Und wer weiß, vielleicht kommen am
                    Ende ja sogar die gleichen Wörter heraus, wie bei
                    Hölderlin?!
                  </Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Paragraph>
                    Nimm eine Zeitungsseite mit dazu und gestalte eine Collage.
                    Du kannst sie zerschneiden oder auch in Einzelteile
                    zerreißen. Wenn du magst, kannst du auch noch andere
                    Materialien für deine Collage verwenden. Klebe sie
                    anschließend auf deine Kalenderseite.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={4}>
                  <Paragraph>
                    Zuletzt klebst du deine neu erfundenen Wörter auf die
                    Collage.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={2} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Image
              image={data.beispiel.childImageSharp.gatsbyImageData}
              alt="Kalenderblatt für den Monat Mai mit einer Collage aus Zeitungsausschnitten und Wortschnipseln"
            />
          </Stack>

          <Constrain>
            <Stack>
              <Trivia>
                <p>
                  Hölderlin hat auf diese Weise viele neue Wörter erfunden, die
                  in keinem Wörterbuch zu finden sind. Hier nur ein paar
                  Beispiele:
                  <MarkerHighlight>
                    entgegenglühen, frechgewöhnt, geschäftiglärmend,
                    ruhigschnell
                  </MarkerHighlight>
                  . Vielleicht fallen dir ja noch ganz andere Wortkombinationen
                  ein?
                </p>
              </Trivia>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
